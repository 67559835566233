import { createRouter, createWebHistory } from "vue-router";
import routes from "@/router/routes";
import { useUserStore } from "@/stores/user";
import routesName from "@/utils/constants/routesName";

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  const userStore = useUserStore();
  const roles = to.meta.roles as Array<string> | undefined;

  if (to.matched.some((record) => record.meta.protected)) {
    if (
      userStore.userData?.role &&
      roles &&
      !roles.includes(userStore.userData.role)
    ) {
      next({
        name: routesName.dashboard,
      });
      return;
    }
    if (userStore.accessToken) {
      next();
      return;
    }
    next({
      name: routesName.login,
      query: { redirect: to.path },
    });
    return;
  }
  if (to.matched.some((record) => record.meta.notAuthorized)) {
    if (!userStore.accessToken) {
      next();
    } else {
      next({
        name: routesName.dashboard,
      });
    }
    return;
  }
  next();
});

export default router;
