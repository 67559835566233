import { RouteRecordRaw } from "vue-router";
import routesName from "@/utils/constants/routesName";
import EmptyLayout from "@/layouts/EmptyLayout.vue";
import { UserRoleEnum } from "@/utils/enum/UserRoleEnum";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: routesName.dashboard,
    redirect: {
      name: routesName.courses,
    },
    meta: { protected: true },
  },
  {
    path: "/login",
    name: routesName.login,
    component: () => import("@/views/LoginView.vue"),
    meta: { notAuthorized: true, layout: EmptyLayout },
  },
  {
    path: "/confirm/:token/password",
    name: routesName.confirmAccount,
    component: () => import("@/views/ConfirmAccount.vue"),
    meta: { notAuthorized: true, layout: EmptyLayout },
  },
  {
    path: "/courses",
    name: routesName.courses,
    component: () => import("@/views/CoursesView.vue"),
    meta: { protected: true, title: "Courses" },
  },
  {
    path: "/courses/create",
    name: routesName.courseCreate,
    component: () => import("@/views/CourseCreateView.vue"),
    meta: { protected: true, title: "Create course" },
  },
  {
    path: "/courses/:id/edit",
    name: routesName.courseEdit,
    component: () => import("@/views/CourseEditView.vue"),
    meta: { protected: true, title: "Editing the course" },
  },
  {
    path: "/learning-centers",
    name: routesName.learningCenters,
    component: () => import("@/views/LearningCentersView.vue"),
    meta: { protected: true, title: "Learning center" },
  },
  {
    path: "/learning-centers/create",
    name: routesName.learningCenterCreate,
    component: () => import("@/views/LearningCenterCreateView.vue"),
    meta: { protected: true, title: "Create learning center" },
  },
  {
    path: "/learning-centers/:id/edit",
    name: routesName.learningCenterEdit,
    component: () => import("@/views/LearningCenterEditView.vue"),
    meta: { protected: true, title: "Editing the learning center" },
  },
  {
    path: "/users",
    name: routesName.users,
    component: () => import("@/views/UsersView.vue"),
    meta: { protected: true, title: "Users", roles: [UserRoleEnum.admin] },
  },
  {
    path: "/managers",
    name: routesName.managers,
    component: () => import("@/views/ManagersView.vue"),
    meta: {
      protected: true,
      title: "User management",
      roles: [UserRoleEnum.admin],
    },
  },
  {
    path: "/managers/create",
    name: routesName.managerCreate,
    component: () => import("@/views/ManagersCreateView.vue"),
    meta: {
      protected: true,
      title: "Create new administrator",
      roles: [UserRoleEnum.admin],
    },
  },
  {
    path: "/user/:id",
    name: routesName.singleUser,
    component: () => import("@/views/SingleUserView.vue"),
    meta: {
      protected: true,
      title: "User info",
      roles: [UserRoleEnum.admin],
    },
  },
  {
    path: "/blog",
    name: routesName.blog,
    component: () => import("@/views/BlogView.vue"),
    meta: { protected: true, title: "Blog" },
  },
  {
    path: "/trending-specialties",
    name: routesName.trendingSpecialties,
    component: () => import("@/views/TrendingSpecialtiesView.vue"),
    meta: {
      protected: true,
      title: "Trending specialties",
      roles: [UserRoleEnum.admin],
    },
  },
  {
    path: "/:pathMatch(.*)*",
    name: routesName.notFound,
    meta: { protected: true },
    component: () => import("@/views/NotFoundView.vue"),
  },
];

export default routes;
